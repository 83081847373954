export const themes = [
  {
    label: 'Default',
    value: 'default',
  },
  {
    label: 'Black / White',
    value: 'black-white',
  },
  {
    label: 'PressComm Tech',
    value: 'presscomm-tech',
  },
  {
    label: 'Maggioli',
    value: 'maggioli',
  },
  {
    label: 'Maggioli Blue',
    value: 'maggioli-blue',
  },
  {
    label: 'MuNDA',
    value: 'munda',
  },
  {
    label: '25 Novembre',
    value: 'donne-25-nov',
  },
  {
    label: 'E.ON',
    value: 'e-on',
  },
  {
    label: 'Confindustria Emilia',
    value: 'conf-emilia',
  },
];

export const fixedPlayerDevices = [
  {
    value: 'mobile',
    label: 'Mobile',
  },
  {
    value: 'desktop',
    label: 'Desktop',
  },
];
